import { motion, useAnimation, useInView } from "framer-motion";

const ScrollDown = ({ className }: Props) => {
    return (
        <div className={"flex justify-center cursor-pointer " + className}>
            <motion.svg width="23" height="35" viewBox="0 0 23 35" fill="none" xmlns="http://www.w3.org/2000/svg"
                initial={{ translateY: "-6px" }}
                animate={{ translateY: ["-6px", "0px", "-6px"] }}
                transition={{
                    duration: 1.5,
                    repeat: Infinity,
                    repeatType: "reverse",
                }}>
                <g clipPath="url(#clip0_33_1828)">
                    <path d="M14.5471 34.644C16.4915 34.117 18.2331 33.0945 19.6211 31.7301C21.7047 29.6822 22.9971 26.8567 22.9971 23.7463V11.3021C22.9971 8.19173 21.7047 5.36621 19.6211 3.31828C17.5376 1.27034 14.663 0 11.4986 0C8.33703 0 5.45949 1.27034 3.37596 3.31828C1.29243 5.36621 0 8.19458 0 11.3021V23.7435C0 26.8538 1.29243 29.6794 3.37596 31.7273C4.99584 33.3195 7.09097 34.4417 9.42951 34.8633C10.8436 35.1196 13.243 34.9972 14.5471 34.644ZM11.3681 26.2215C12.5707 26.2215 13.556 25.2531 13.556 24.071V21.8551C13.556 20.673 12.5707 19.7046 11.3681 19.7046C10.1656 19.7046 9.18029 20.673 9.18029 21.8551V24.071C9.18029 25.2531 10.1627 26.2215 11.3681 26.2215ZM17.6999 29.8417C16.109 31.4054 13.9124 32.3796 11.4986 32.3796C9.08467 32.3796 6.89102 31.4083 5.29722 29.8417C3.70631 28.278 2.71526 26.119 2.71526 23.7463V11.3021C2.71526 8.92944 3.70341 6.77328 5.29722 5.20671C6.88812 3.64299 9.08467 2.66886 11.4986 2.66886C13.9124 2.66886 16.1061 3.64014 17.6999 5.20671C19.2908 6.77043 20.2818 8.92944 20.2818 11.3021V23.7435C20.2818 26.119 19.2937 28.2751 17.6999 29.8417Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_33_1828">
                        <rect width="23" height="35" fill="white" />
                    </clipPath>
                </defs>
            </motion.svg>
        </div>
    );
};

interface Props {
    className: string;
}

export default ScrollDown;