const projects = [
    {
        id: "01",
        views: "6.5k",
        title: "Project One",
        link: "https://www.sakulaindustries.ca/",
        thumbnail: "assets/images/png/project-one-new-img-2.png",
        description: "Lorem Ipsum is simply dummy text of theand typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    },
    {
        id: "02",
        views: "15k",
        title: "Project Two",
        link: "https://dinjiizhuhsolutions.com/",
        thumbnail: "assets/images/webp/project-image-7.jpeg",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the standard dummy text ever since the 1500s.",
    },
    {
        id: "03",
        views: "6.5k",
        title: "Project Three",
        link: "https://www.figma.com/file/nqznf5jGEAduOLmBBhIs0C/Painter?type=design&node-id=0-1&mode=design",
        thumbnail: "assets/images/webp/project-image-1.webp",
        description: "Lorem Ipsum is simply dummy text of theand typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    },
    {
        id: "04",
        views: "5.43k",
        title: "Project Four",
        link: "https://www.figma.com/file/xJIDMVN4Mx9Vno1rUO7d84/music-artist?type=design&node-id=0%3A1&mode=design&t=7nhGmmCOB9fr6zR0-1",
        thumbnail: "assets/images/webp/project-image-2.webp",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Ipsum has been the industry's standard dummy text ever since the 1500s.",
    },
    {
        id: "05",
        views: "4.2k",
        title: "Project Five",
        link: "https://www.figma.com/file/USAqGM4TuGMgCn4Q6Mhd31/Law-firm?type=design&node-id=0-1&mode=design",
        thumbnail: "assets/images/webp/project-image-3.webp",
        description: "Lorem Ipsum is simply text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
    },
    {
        id: "06",
        views: "15k",
        title: "Project Six",
        link: "https://www.figma.com/file/IYE4lBFcczS71tGXwHHnwQ/Tour-guide?type=design&node-id=0-1&mode=design",
        thumbnail: "assets/images/webp/project-image-4.webp",
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the standard dummy text ever since the 1500s.",
    },
    

        
];

export {projects};
