import TextAnimation from "../animation/TextAnimation";
import { useNavigate } from "react-router";

export default function Footer() {
  const navigate = useNavigate();
  return (
    <>
      <div className="absolute w-full text-white text-[12px] md:text-[16px] lg:text-[18px] font-normal font-['Raleway'] bottom-4">
      <TextAnimation customProps={{ delay: 0.5 }}>
      <div className="flex flex-col justify-center items-center">
        <h1 className='flex justify-center'>Copyright 2023. Qouda.ca. All rights reserved.</h1>
        <button
          onClick={(e: any) => {
              
              setTimeout(() => {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth' 
                  });
                  navigate("/privacy", { replace: true });
              });
          }}
          className="">
          <div className="text-white cursor-pointer pt-3 md:text-[14px] text-[12px] pb-4">Privacy and policy</div>
        </button>
      </div>
      </TextAnimation>
      </div>
    </>
  );
}
