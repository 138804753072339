export const serviceData = [
   {
      id: 1,
      svg: "assets/images/svg/web-design-vector-icon.svg",
      serviceType: "Web Design",
      serviceDescription:
         "We push the limits of the current industry standards to create an online presence by curating websites that convey your brands message. We make your website look like it belongs on the wall of a gallery by crafting each detail in alignment with your brands ethos to create an aesthetic online persona.",
   },
   {
      id: 2,
      svg: "assets/images/svg/web-development-vector-icon.svg",
      serviceType: "Web Development",
      serviceDescription:
         "Our emphasis is on clear milestones, effective client communication and timely delivery. We make working with us as easy as ordering takeout. We utilize the best modern technologies to develop a robust foundation on which your online presence rests.",
   },
];

export default serviceData;
