import { IoArrowBack } from "react-icons/io5";
import { privacy } from "../../config/privacy";
import TextAnimation from "../animation/TextAnimation";
import BoxAnimation from "../animation/BoxAnimation";
import { useLocation } from "react-router-dom";


export default function Privacy() {
  
  return (
    <div className='bg-[#fff] h-full font-poppins pb-5'>
      <BoxAnimation customProps={{from: -75, delay: 1}}>
      <div className='flex justify-between px-[24px] sm:px-[36px] md:px-[96px] lg:px-[184px] py-6'>
        <div className="">
          <a href="/">
            <img src="assets/images/png/Qouda-dark.png" alt="QOUDA LOGO" className='h-5 lg:h-7' /> 
          </a>
        </div>
        <div className="cursor-pointer hover:text-primary transition-all font-semibold font-poppins text-[14px] lg:text-[16px]">
          <a href="/">
            <p className=''>Home</p>
          </a>
        </div>
        
      </div>
      </BoxAnimation>
      <div className="bg-[#fafafa] px-[24px] sm:px-[36px] md:px-[96px] lg:px-[184px] py-[48px] ">
        <div className="">
          <TextAnimation customProps={{ delay: 1 }}> 
            <div className="font-semibold font-poppins text-[14px] bg-red lg:text-[16px] flex gap-1 ">
              <a href="/" className="flex cursor-pointer items-center justify-center hover:text-primary text-black transition-all gap-1">
                <IoArrowBack className="h-[12px] "/>
                <p className=' text-[12px] md:text-[14px]'>Back</p>
              </a>
            </div>
          </TextAnimation>
        </div>
        <TextAnimation customProps={{ delay: 1 }}> 
          <div  className="text-[42px] max-md:text-[36px] max-md:text-center max-sm:text-[28px] font-black mt-[12px]">
            <p>{privacy.heading.title}</p>
          </div>     
          <div className="text-[16px] max-md:text-[13px] max-md:text-center max-sm:text-[12px] font-semibold text-[#848484] italic -mt-1">
            <p>{privacy.heading.updatedTime}</p>
          </div>
        </TextAnimation>     
      </div>
      
      <BoxAnimation customProps={{from: -75, delay: 1}}>
        <div className="px-[24px] sm:px-[36px] md:px-[96px] lg:px-[184px] pb-2">
          <div className="text-[32px] max-md:text-[26px] text-center max-sm:text-[22px] pb-[3px] font-black mt-8">
            {privacy.content.paragraphOne.title}
          </div>
          <div className="text-[16px] max-md:text-[14px] max-sm:text-[12px] max-md:text-center font-medium  mt-2 italic">
            {privacy.content.paragraphOne.paragraph}
          </div>
        </div>
      </BoxAnimation>
      <BoxAnimation customProps={{from: -75, delay: 1}}>
        <div className="px-[24px] sm:px-[36px] md:px-[96px] lg:px-[184px] pb-2">
          <div className="text-[32px] max-md:text-[26px] leading-tight max-md:text-center pb-[3px] max-sm:text-[22px] font-black max-sm:items-center mt-6">
            {privacy.content.paragraphTwo.title}
          </div>
          <div className="text-[16px] max-md:text-[14px] max-md:text-center max-sm:text-[12px] font-normal mt-2">
            {privacy.content.paragraphTwo.paragraph}
          </div>
        </div>
      </BoxAnimation>
      <BoxAnimation customProps={{from: -75, delay: 1}}>
        <div className="px-[24px] sm:px-[36px] md:px-[96px] lg:px-[184px] pb-2">
          <div className="text-[32px] max-md:text-[26px] leading-tight max-md:text-center pb-[3px] max-sm:text-[22px] max-sm:items-center font-black mt-6">
            {privacy.content.paragraphThree.title}
          </div>
          <div className="text-[16px] max-md:text-[14px] max-md:text-center max-sm:text-[12px] font-normal mt-2">
            {privacy.content.paragraphThree.paragraph1}
          </div>
          <div className="text-[16px] max-md:text-[14px] max-md:text-center max-sm:text-[12px] font-normal mt-2">
            {privacy.content.paragraphThree.paragraph2}
          </div>
          <div className="text-[16px] max-md:text-[14px] max-md:text-center max-sm:text-[12px] font-normal mt-2">
            {privacy.content.paragraphThree.paragraph3}
          </div>
          <div className="text-[16px] max-md:text-[14px] max-md:text-center max-sm:text-[12px] font-normal mt-2">
            {privacy.content.paragraphThree.paragraph4}
          </div>
        </div>
      </BoxAnimation>
      <BoxAnimation customProps={{from: -75, delay: 1}}>
        <div className="px-[24px] sm:px-[36px] md:px-[96px] lg:px-[184px] pb-2">
          <div className="text-[32px] max-md:text-[26px] leading-tight max-md:text-center pb-[3px] max-sm:text-[22px] max-sm:items-center font-black mt-6">
            {privacy.content.paragraphFour.title}
          </div>
          <div className="text-[16px] max-md:text-[14px] max-md:text-center max-sm:text-[12px] font-normal mt-2">
            {privacy.content.paragraphFour.paragraph1}
          </div>
          <div className="text-[16px] max-md:text-[14px] max-md:text-center max-sm:text-[12px] font-normal mt-2">
            {privacy.content.paragraphFour.paragraph2}
          </div>
        </div>
      </BoxAnimation>
      <BoxAnimation customProps={{from: -75, delay: 1}}>
        <div className="px-[24px] sm:px-[36px] md:px-[96px] lg:px-[184px] pb-2">
          <div className="text-[32px] max-md:text-[26px] leading-tight max-md:text-center pb-[3px] max-sm:text-[22px] max-sm:items-center font-black mt-6">
            {privacy.content.paragraphFive.title}
          </div>
          <div className="text-[16px] max-md:text-[14px] max-md:text-center max-sm:text-[12px] font-normal mt-2">
            {privacy.content.paragraphFive.paragraph}
          </div>
        </div>
      </BoxAnimation>
      <BoxAnimation customProps={{from: -75, delay: 1}}>
        <div className="px-[24px] sm:px-[36px] md:px-[96px] lg:px-[184px] pb-2">
          <div className="text-[32px] max-md:text-[26px] leading-tight max-md:text-center pb-[3px] max-sm:text-[22px] max-sm:items-center font-black mt-6">
            {privacy.content.paragraphSix.title}
          </div>
          <div className="text-[16px] max-md:text-[14px] max-md:text-center max-sm:text-[12px] font-normal mt-2">
            {privacy.content.paragraphSix.paragraph}
          </div>
        </div>
      </BoxAnimation>
      <BoxAnimation customProps={{from: -75, delay: 1}}>
        <div className="px-[24px] sm:px-[36px] md:px-[96px] lg:px-[184px] pb-2">
          <div className="text-[32px] max-md:text-[26px] leading-tight max-md:text-center pb-[3px] max-sm:text-[22px] max-sm:items-center font-black mt-6">
            {privacy.content.paragraphSeven.title}
          </div>
          <div className="text-[16px] max-md:text-[14px] max-md:text-center max-sm:text-[12px] font-normal mt-2">
            {privacy.content.paragraphSeven.paragraph}
          </div>
        </div>
      </BoxAnimation>
      <BoxAnimation customProps={{from: -75, delay: 1}}>
        <div className="px-[24px] sm:px-[36px] md:px-[96px] lg:px-[184px] pb-2">
          <div className="text-[32px] max-md:text-[26px] leading-tight max-md:text-center pb-[3px] max-sm:text-[22px] max-sm:items-center font-black mt-6">
            {privacy.content.paragraphEight.title}
          </div>
          <div className="text-[16px] max-md:text-[14px] max-md:text-center max-sm:text-[12px] font-normal mt-2">
            {privacy.content.paragraphEight.paragraph1}
          </div>
          <div className="text-[16px] max-md:text-[14px] max-md:text-center max-sm:text-[12px] font-normal mt-2">
            {privacy.content.paragraphEight.paragraph2}
          </div>
        </div>
      </BoxAnimation>
      <BoxAnimation customProps={{from: -75, delay: 1}}>
        <div className="px-[24px] sm:px-[36px] md:px-[96px] lg:px-[184px] pb-2">
          <div className="text-[32px] max-md:text-[26px] leading-tight max-md:text-center pb-[3px] max-sm:text-[22px] max-sm:items-center font-black mt-6">
            {privacy.content.paragraphNine.title}
          </div>
          <div className="text-[16px] max-md:text-[14px] max-md:text-center max-sm:text-[12px] font-normal mt-2">
            {privacy.content.paragraphNine.paragraph1}
          </div>
          <div className="text-[16px] max-md:text-[14px] max-md:text-center max-sm:text-[12px] font-normal mt-2">
            {privacy.content.paragraphNine.paragraph2}
          </div>
          <div className="text-[16px] max-md:text-[14px] max-md:text-center max-sm:text-[12px] font-normal mt-2">
            {privacy.content.paragraphNine.paragraph3}
          </div>
          <div className="text-[16px] max-md:text-[14px] max-md:text-center max-sm:text-[12px] font-normal mt-2">
            {privacy.content.paragraphNine.paragraph4}
          </div>
        </div>
      </BoxAnimation>
      <TextAnimation customProps={{ delay: 1 }}> 
        <div className="px-[24px] sm:px-[36px] md:px-[96px] lg:px-[184px] mt-16 bg-[#fafafa] py-3">
          <div className="text-[14px] max-md:text-[14px] text-center max-sm:text-[12px] font-medium italic">
            {privacy.content.footer.footerText}
          </div>
        </div>
      </TextAnimation>

    </div>
  )
}
