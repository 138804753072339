export const privacy = {
  heading:{
    title:"Privacy Policy",
    updatedTime: "Last update September 5th, 2024."
  },
  content:
    {
      paragraphOne:{
        title:"",
        paragraph:"At Qouda Inc., we are committed to protecting the privacy of our clients, employees, and any other individuals whose personal information we collect. This privacy policy explains how we collect, use, and disclose personal information."
      },
      paragraphTwo:{
        title:"Personal Information",
        paragraph:"\“Personal Information\” is defined as information about an identifiable individual other than the individual\’s name, title and business contact information."
      },
      paragraphThree:{
        title:"How Personal Information is Collected",
        paragraph1:"Qouda Inc.  must collect, use and retain personal information with respect to its employees, in order to engage in employment relationships and comply with its legal obligations.",
        paragraph2:"In its dealings with clients, Qouda Inc. collects personal information directly from clients, when the client provides this information to us, either in person, over the phone, over email or through the Qouda Inc. websites or social media.",
        paragraph3:"This may include a client\’s name and address, telephone number, email address, credit card/banking information, and any other information required to deliver our products and services, including any information required in order for Qouda to comply with its legal obligations.",
        paragraph4:"Qouda Inc. will endeavour to only collect the information required to deliver its products and services.",
      },
      paragraphFour:{
        title:"Use and Disclosure of Personal Information.",
        paragraph1:"Qouda Inc. uses personal information collected from clients or other individuals in order to deliver its products and services.",
        paragraph2:"Qouda Inc. will not share the personal information of clients or other individuals with third parties without the individual\’s consent, except as required by law.",
      },
      paragraphFive:{
        title:"Safeguarding Personal Information",
        paragraph:"Qouda Inc. is responsible for the personal information within its possession and control. Qouda Inc. has appointed the Directors as its Privacy Officers. The Privacy Officers are the custodians of all privacy matters and accountable for legal compliance with privacy laws and the principles outlined in this Policy.\t Qouda Inc. will take reasonable measures to protect personal information from unauthorized access, use, and disclosure. These measures include physical, administrative, and technological safeguards."
      },
      paragraphSix:{
        title:"Retention of Personal Information",
        paragraph:"Qouda Inc. retains personal information only as long as necessary to fulfill the purposes for which it was collected, or as required by law. "
      },
      paragraphSeven:{
        title:"Access to Personal Information",
        paragraph:"Individuals may request access to their personal information by making a written request to the Privacy Officer. Access will be provided as required and permitted by law."
      },
      paragraphEight:{
        title:"Changes to this Privacy Policy",
        paragraph1:"Qouda Inc. will review this privacy policy every two (2) years and may revise the policy from time to time. Any changes will be posted on Qouda Inc\’s website.",
        paragraph2:"This Policy was last revised September 5th, 2024.",
      },
      paragraphNine:{
        title:"Privacy Officer",
        paragraph1:"An Employee, client or other individuals may address inquiries or complaints concerning compliance with this Privacy Policy and the accuracy of information held by Qouda Inc., to the Directors who serve as the Privacy Officers, in writing.",
        paragraph2:"Qouda Inc. will investigate all complaints concerning compliance and its Privacy Policy and, if necessary, will take appropriate measures to remedy the issue resulting in the complaint, including correcting information handling practices and policies, where necessary.",
        paragraph3:"Complainants will be notified in writing of the outcome of an investigation clearly and promptly and will be informed of any relevant steps taken.",
        paragraph4:"Inquiries or complaints should be submitted to the Privacy Officer at: admin@qouda.ca.",
      },
      footer:{
        footerText: "Copyright 2023. Qouda.ca. All rights reserved."
      },
    }
  
}