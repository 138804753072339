import React, { useEffect, useState } from "react";

const Preloader = ({ layer }: any) => {
    const [animation, setAnimation] = useState<string>("");
    const [init, setInit] = useState<boolean>(false);

    /**
    * Visible and hide the landing section according to layer property
    */
    useEffect(() => {
        setAnimation("preloader-animation-theme-three-init");
        setTimeout(() => {
            setInit(true);
            setAnimation("");
        }, 3000);
    }, []);

    /**
     * Visible and hide the landing section according to layer property
    */
    useEffect(() => {
        if (init) {
            setAnimation("preloader-animation-theme-three");
            setTimeout(() => {
                setAnimation("");
            }, 3000);
        }
    }, [layer]);

    return (
        <div style={{zIndex: 111111}} className={"absolute w-full h-screen top-[-130%] left-0 right-0 flex items-center justify-center bg-[#aee3ff] shadow-sm " + animation}>
            <svg width="100" height="100" viewBox="0 0 800 821" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.9" clipPath="url(#clip0_7_149)">
                    <path d="M403.326 0C422.037 0 440.748 2.52357 459.044 6.72951C465.696 7.99129 472.765 7.99129 479.418 9.67367C650.312 43.3212 776.299 193.473 782.536 370.123C784.2 411.762 782.952 452.559 776.299 493.357C773.804 508.919 768.815 524.061 763.825 542.567C732.224 498.825 697.713 461.813 651.975 439.942C622.869 426.062 615.385 408.818 613.306 377.273C608.316 295.257 577.131 224.177 503.118 181.697C365.904 102.625 201.663 183.379 174.636 341.943C163.41 406.715 166.736 470.224 194.179 531.211C240.748 634.256 341.372 673.372 441.996 652.342C459.875 648.556 469.439 651.501 479.002 666.642C499.792 699.028 516.84 733.096 529.73 769.688C533.888 781.885 531.393 787.353 519.335 791.138C307.692 854.227 63.2017 759.173 9.14761 497.984C7.90021 492.095 8.31601 485.786 7.48441 479.898C3.3264 456.765 0 433.633 0 410.079V395.359C0 374.75 2.079 354.561 6.65281 334.372C7.48441 331.849 7.06861 328.905 7.90021 325.961C36.1746 172.023 121.414 68.5569 269.854 18.9267C286.902 12.6178 304.782 11.356 321.83 7.5707C342.62 2.94416 363.825 0 385.447 0H403.326Z" fill="#EE302E" />
                    <path d="M800 821C772.557 790.297 744.698 767.164 710.187 754.546C684.407 744.872 658.212 739.825 630.769 744.031C617.879 746.134 611.642 741.508 605.405 729.731C548.857 621.638 464.449 549.717 344.698 523.219C331.809 520.275 318.919 519.434 306.029 516.91C298.545 515.649 289.813 513.125 289.397 503.872C288.981 496.301 298.129 496.301 303.534 493.778C523.493 397.041 725.572 530.369 786.279 724.684C795.426 754.546 800 784.829 800 821Z" fill="#EE302E" />
                </g>
                <defs>
                    <clipPath id="clip0_7_149">
                        <rect width="800" height="821" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default Preloader;