const rippleEffectDuration = 2000;

let toastifyConfigs = {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
};

const EMAIL_SERVICE_KEY = "service_b3md4gt";
const EMAIL_TEMPLATE_ID = "template_o2swg7x";
const EMAIL_PUBLIC_KEY = "QlJpUryIPR7f5c7_6";

export { 
    rippleEffectDuration,
    toastifyConfigs,
    EMAIL_SERVICE_KEY,
    EMAIL_TEMPLATE_ID,
    EMAIL_PUBLIC_KEY,
};
